<template>
  <div class="animated">
    <b-card>
      <div slot="header"><i class="fab fa-twitch"></i> Twitch</div>

      <b-button
        class="twitchbutton"
        v-on:click="openCreateModal"
        :disabled="!canAdd"
      >
        <font-awesome-icon :icon="['fab', 'twitch']" class="mr-2" />Add new
        twitch stream
      </b-button>
      <div v-if="!canAdd" class="danger-text">
        You have reached your limit of {{ channelLimit }} channels.
        <br />
        <p v-if="!isPremium">Premium members get {{ twitchLimitsVip }}</p>
      </div>

      <hr />
      <v-client-table
        :columns="columns"
        :data="notifications"
        :options="options"
        :theme="theme"
        id="dataTable"
        style="width: 100%"
      >
        <p slot="stream" slot-scope="props" block>
          {{ props.row.stream_name }}
        </p>
        <p slot="channel" slot-scope="props" block>
          #{{ channel_from_id(props.row.channel_id).name }}
        </p>
        <b-container
          slot="options"
          class="d-md-inline-block"
          slot-scope="props"
        >
          <b-row>
            <b-col>
              <b-button block variant="primary" v-on:click="openEdit(props.row)"
                >Edit</b-button
              >
            </b-col>
            <b-col>
              <b-button
                block
                variant="outline-danger"
                v-on:click="deleteStreamer(props.row)"
              >
                Delete
                <i class="icon-trash"></i>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </v-client-table>
    </b-card>
    <b-modal size="md" v-model="createModal" :no-enforce-focus="true">
      <template slot="modal-title">
        <h4 class="smalltitle">
          <font-awesome-icon :icon="['fab', 'twitch']" class="mr-2" />Add twitch
          stream
        </h4>
      </template>
      <label for="ajax" class="teenytinytitle">Twitch name</label>
      <multiselect
        v-model="selectedChannel"
        id="ajax"
        label="name"
        track-by="id"
        placeholder="Please enter a channel name"
        open-direction="bottom"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="true"
        :options-limit="300"
        :limit="3"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="false"
        @search-change="searchForStreams"
        :options="sortedChannels"
        :allow-empty="false"
        @close="isLoading = false"
        deselect-label="Selected"
      >
        <template slot="singleLabel" slot-scope="props">
          <img class="option__image" :src="props.option.logo" width="24" />
          <span class="option__desc">
            <span class="option__title ml-2">{{
              props.option.display_name
            }}</span>
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <img
              class="option__image mr-2"
              :src="props.option.logo"
              width="24"
            />
            <span class="option__title">
              {{ props.option.display_name }}
              <font-awesome-icon
                v-show="props.option.partner"
                style="color:#9147ff"
                icon="check-circle"
              />
            </span>

            <span class="option__small"></span>
          </div>
        </template>
      </multiselect>

      <label for="discord_channel_select" class="teenytinytitle mt-4"
        >Channel</label
      >
      <multiselect
        v-model="discordChannel"
        id="discord_channel_select"
        track-by="id"
        label="name"
        placeholder="Please select a channel"
        open-direction="bottom"
        :searchable="true"
        :clear-on-select="false"
        :close-on-select="true"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="false"
        :options="groupedChannels"
        :allow-empty="false"
        group-values="channels"
        group-label="category"
        :group-select="false"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <font-awesome-icon style="color:#72767d" icon="hashtag" />
            <span class="option__title ml-2">{{ props.option.name }}</span>
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">
              <font-awesome-icon
                style="color:#72767d"
                :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
              />
              {{ props.option.name || props.option.$groupLabel.name }}
            </span>
          </div>
        </template>
      </multiselect>
      <label for="announcement_message" class="teenytinytitle mt-4"
        >Announcement message</label
      >

      <b-input-group>
        <b-form-textarea
          :maxlength="2000"
          rows="6"
          type="text"
          placeholder="{name} is now online! {link}"
          class="col-12"
          v-model="announcementMessage"
          id="announcement_message"
        ></b-form-textarea>
      </b-input-group>
      <div slot="modal-footer" class="w-100">
        <b-container
          fluid
          class="text-light text-center"
          style="padding-right:0px;"
        >
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button
                variant="ghost-secondary"
                v-on:click="createModal = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button
                class="twitchbutton"
                v-on:click="addStreamer"
                :disabled="!discordChannel || !selectedChannel"
                >Add</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal size="md" v-model="editModal" :no-enforce-focus="true">
      <template slot="modal-title">
        <h4 class="smalltitle">
          <font-awesome-icon :icon="['fab', 'twitch']" class="mr-2" />Edit
          twitch stream
        </h4>
      </template>
      <h4 class="smalltitle">{{ streamName }}</h4>
      <label for="discord_channel_select" class="teenytinytitle mt-4"
        >Channel</label
      >
      <multiselect
        v-model="discordChannel"
        id="discord_channel_select"
        track-by="id"
        label="name"
        placeholder="Please select a channel"
        open-direction="bottom"
        :clear-on-select="false"
        :close-on-select="true"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="false"
        :options="groupedChannels"
        :allow-empty="false"
        group-values="channels"
        group-label="category"
        :group-select="false"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <font-awesome-icon style="color:#72767d" icon="hashtag" />
            <span class="option__title ml-2">{{ props.option.name }}</span>
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">
              <font-awesome-icon
                style="color:#72767d"
                :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
              />
              {{ props.option.name || props.option.$groupLabel.name }}
            </span>
          </div>
        </template>
      </multiselect>

      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <font-awesome-icon style="color:#72767d" icon="hashtag" />
          <span class="option__title ml-2">{{ props.option.name }}</span>
        </span>
      </template>
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">
            <font-awesome-icon style="color:#72767d" icon="hashtag" />
            {{ props.option.name }}
          </span>
        </div>
      </template>
      <label for="announcement_message" class="teenytinytitle mt-4"
        >Announcement message</label
      >

      <b-input-group>
        <b-form-textarea
          :maxlength="2000"
          rows="6"
          type="text"
          placeholder="{name} is now online! {link}"
          class="col-12"
          v-model="announcementMessage"
          id="announcement_message"
        ></b-form-textarea>
      </b-input-group>
      <div slot="modal-footer" class="w-100">
        <b-container
          fluid
          class="text-light text-center"
          style="padding-right:0px;"
        >
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="editModal = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button
                class="twitchbutton"
                v-on:click="editStreamer"
                :disabled="!discordChannel"
                >Edit</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";
import Multiselect from "vue-multiselect";
import debounce from "lodash/debounce";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({
  types: toastTypes,
  timeout: 5000,
  style: {
    "mini-toastr__notification": {
      "margin-top": "200px"
    }
  }
});

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);
Vue.use(ClientTable);

export default {
  name: "DataTable",
  components: {
    Event,
    ClientTable,
    cSwitch,
    Multiselect
  },
  notifications: {
   added_alert: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: " "
    },
    added_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag created"
    },
    edited_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag edited"
    },
    deleted_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag deleted"
    },
    fill_in_fields: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Please fill in all fields"
    },
    no_spaces_thank_you: {
      type: VueNotifications.types.warn,
      title: "Bad tag",
      message:
        "Tags don't really work when they have spaces added, consider replacing them with dashes or underscores"
    }
  },
  data: function() {
    return {
      columns: ["stream_name", "channel", "options"],
      data: [],
      createModal: false,
      isPremium: false,
      editModal: false,
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      isLoading: false,
      discordChannel: null,
      twitchChannels: [],
      selectedChannel: null,
      twitchLimitsNonVip: 1,
      twitchLimitsVip: 20,
      notifications: [],
      channels: [],
      announcementMessage: "",
      streamName: "",
      streamId: "",
      options: {
        filterByColumn: true,
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}"
        },
        sortable: ["stream_name", "channel", "options"],
        headings: {
          stream_name: "stream"
        },
        filterable: ["stream_name"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        },
        childRow: false,
        highlightMatches: true
      },
      theme: "bootstrap4"
    };
  },
  methods: {
    searchForStreams(query) {
      if (!query) {
        return;
      }
      this.isLoading = true;
      this.queryStreams(query);
    },
    channel_from_id(id) {
      return (
        this.channels.find(x => x.id == id) || {
          name: "unknown channel (deleted?)"
        }
      );
    },
    openEdit(row) {
      this.selectedChannel = row;
      this.discordChannel = this.channel_from_id(row.channel_id);
      this.announcementMessage = row.fmt;
      this.streamName = row.stream_name;
      this.streamId = row.stream_id;
      this.editModal = true;
    },
    deleteLink(row) {},
    async addStreamer() {
      await this.axios.put(
        "/api/v1/servers/" + this.$route.params.guild_id + "/twitch",
        {
          add_stream: true,
          stream_id: this.selectedChannel.id,
          stream_name: this.selectedChannel.display_name,
          channel_id: this.discordChannel.id,
          announce_message: this.announcementMessage
        }
      ).then(r => {
           if(r.status === 200){
           this.added_alert({message: "Successfully Added!"})
           }
        });
      this.createModal = false;
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].stream_id == this.selectedChannel.id) {
          this.notifications[i].channel_id = this.discordChannel.id;
          this.notifications[i].fmt = this.announcementMessage;
          return;
        }
      }
      this.notifications.push({
        stream_id: this.selectedChannel.id,
        stream_name: this.selectedChannel.display_name,
        fmt: this.announcementMessage,
        channel_id: this.discordChannel.id
      });
    },
    async editStreamer() {
      await this.axios.put(
        "/api/v1/servers/" + this.$route.params.guild_id + "/twitch",
        {
          add_stream: false,
          stream_id: this.streamId,
          stream_name: this.streamName,
          channel_id: this.discordChannel.id,
          announce_message: this.announcementMessage
        }
      ).then(r => {
           if(r.status === 200){
           this.added_alert({message: "Successfully Edited!"})
           }
        });
      this.editModal = false;
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].stream_id == this.streamId) {
          this.notifications[i].channel_id = this.discordChannel.id;
          this.notifications[i].fmt = this.announcementMessage;
          break;
        }
      }
    },
    async deleteStreamer(row) {
      await this.axios.delete(
        "/api/v1/servers/" +
          this.$route.params.guild_id +
          "/twitch/" +
          row.stream_id
      ).then(r => {
           if(r.status === 200){
           this.added_alert({message: "Successfully Deleted!"})
           }
        });
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].stream_id == row.stream_id) {
          this.notifications.splice(i, 1);
        }
      }
    },
    openCreateModal() {
      this.selectedChannel = null;
      this.discordChannel = null;
      this.twitchChannels = [];
      this.announcementMessage = "";
      this.createModal = true;
    },
    queryStreams: debounce(function(query) {
      this.axios
        .get("/api/v1/servers/" + this.$route.params.guild_id + "/twitch/channels?query=" + query)
        .then(r => {
          console.log(r);
          this.twitchChannels = r.data.data;
          this.isLoading = false;
        })
        .catch(e => {
          this.twitchChannels = [];
          this.isLoading = false;
        });
    }, 1000)
  },
  computed: {
    channelLimit() {
      return this.isPremium ? this.twitchLimitsVip : this.twitchLimitsNonVip;
    },
    canAdd() {
      return this.notifications.length < this.channelLimit;
    },
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
    sortedChannels() {
      return this.twitchChannels.sort((a, b) => {
        if (a.partner && !b.partner) {
          return -1;
        }
        if (b.partner && !a.partner) {
          return 1;
        }
        return a.display_name > b.display_name;
      });
    }
  },
  created() {
    this.$Progress.start();
    const db = this.axios.get(
      "/api/v1/servers/" + this.$route.params.guild_id + "/twitch"
    );
    const chns = this.axios.get(
      "/api/v1/servers/" +
        this.$route.params.guild_id +
        "/channels?categories=true"
    );
    this.axios.all([db, chns]).then(
      this.axios.spread((...responses) => {
        const resp = responses[0];
        const channels = responses[1];
        const twitchLimits = (resp.data && resp.data.twitchLimits) || {};
        this.notifications = (resp.data && resp.data.subscriptionList) || [];
        this.isPremium = resp.data && resp.data.isPremium === 1;
        this.twitchLimitsVip = twitchLimits.VIP;
        this.twitchLimitsNonVip = twitchLimits.NON_VIP;
        this.channels = channels.data;
        this.$Progress.finish();
      })
    );
  }
};
</script>
<style lang="scss">
#dataTable {
  width: 95%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
<style scoped>
.danger-text {
  color: #ff0000;
}
.twitchbutton {
  background-color: #9147ff;
  border-color: #9147ff;
}
.twitchbutton:active {
  background-color: #9147ff !important;
  border-color: #9147ff !important;
}
.twitchbutton:hover {
  background-color: #8137ef;
  border-color: #8137ef;
}
</style>
